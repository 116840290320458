import { FC } from 'react';

import { NavigationItemSecondLevel } from '@/components/layout/Header/types';
import { SBLink } from '@/components/shared/SBLink';

import { HeaderLink } from '../../../HeaderLink';

interface Props {
  node: NavigationItemSecondLevel[];
  className: string;
}

export const NavigationItemSecondLevelDesktop: FC<Props> = ({ node, className }) => {
  return (
    <nav className={className}>
      <ul className="flex flex-col items-end gap-[1.6rem]">
        {node.map(({ name, _uid, link }) => (
          <li key={_uid}>
            <SBLink link={link}>
              <HeaderLink level={2}>{name}</HeaderLink>
            </SBLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
