import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

import { NavigationItemSecondLevel } from '@/components/layout/Header/types';
import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';
import { SBLink } from '@/components/shared/SBLink';

import { ToggleFunc } from '@/hooks/useToggle';

import { listItemVariants, listVariants, menuNavVariants } from '../../../../animations';
import { HeaderLink } from '../../../HeaderLink';

interface Props {
  node: NavigationItemSecondLevel[];
  isOpen: boolean;
  onMenuClose: ToggleFunc;
  backLabel?: string;
}

const wrapperStyles = clsx(
  'lg:hidden absolute top-0 right-0 z-30',
  'w-full h-[calc(100vh-7rem)]',
  'bg-gray-100 px-[3.2rem] pt-[4.4rem] pb-[2rem]'
);

export const NavigationItemSecondLevelMobile: FC<Props> = ({
  node,
  isOpen = false,
  onMenuClose,
  backLabel
}) => {
  return (
    <AnimatePresence initial={false} mode="wait">
      {isOpen && (
        <motion.div
          variants={menuNavVariants}
          initial="hidden"
          animate="visible"
          exit="hidden"
          className={wrapperStyles}
        >
          <nav>
            <button onClick={onMenuClose} className="group flex items-center gap-[1.6rem] p-2">
              <ArrowIcon className="text-primary rotate-180" />
              <span className="text-black text-[2.4rem] leading-[3rem] group-focus:text-primary group-active:text-primary">
                {backLabel}
              </span>
            </button>
            <motion.ul variants={listVariants} className="flex flex-col gap-[3.2rem] mt-[4.4rem]">
              {node.map(({ link, name, _uid }) => (
                <motion.li variants={listItemVariants} key={_uid}>
                  <SBLink link={link}>
                    <HeaderLink level={1}>{name}</HeaderLink>
                  </SBLink>
                </motion.li>
              ))}
            </motion.ul>
          </nav>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
