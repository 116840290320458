import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';

import { ToggleFunc } from '@/hooks/useToggle';

import { listItemVariants, listVariants, menuNavVariants } from '../../animations';
import { NavigationItem } from '../../types';
import { Hamburger } from '../Hamburger';
import { HeaderNavigationItem } from '../HeaderNavigationItem/HeaderNavigationItem';

interface Props {
  data: NavigationItem[];
  hamburgerLabel?: string;
  backLabel?: string;
  isOpen: boolean;
  onMenuClose: ToggleFunc;
}

const wrapperStyles = clsx(
  'fixed top-[12rem] right-0 z-20 overflow-hidden',
  'w-full h-[calc(100vh-12rem)]',
  'bg-gray-100 px-[3.2rem] pt-[4.4rem] pb-[2rem]',
  'lg:top-0 lg:h-screen lg:w-[81.6rem] lg:pr-[16rem]'
);

export const HeaderNavigation: FC<Props> = ({
  data,
  hamburgerLabel,
  backLabel,
  isOpen,
  onMenuClose
}) => {
  const handleCLoseMenu = () => onMenuClose(false);

  return (
    <AnimatePresence initial={false} mode="wait">
      {isOpen && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={menuNavVariants}
          className={wrapperStyles}
        >
          <div className="flex flex-col lg:items-end pb-[2rem] overflow-hidden h-full">
            <div className="hidden lg:block">
              <Hamburger isOpen label={hamburgerLabel} onClick={handleCLoseMenu} />
            </div>
            <motion.ul
              variants={listVariants}
              className="flex flex-col gap-[2.6rem] lg:items-end lg:mt-[8rem] overflow-y-hidden max-h-[75rem] h-full lg:max-h-[120rem] w-full overflow-x-hidden lg:overflow-y-auto"
            >
              {data.map(({ link, name, _uid, children }) => (
                <motion.li key={_uid} variants={listItemVariants}>
                  <HeaderNavigationItem
                    link={link}
                    node={children}
                    name={name}
                    backLabel={backLabel}
                  />
                </motion.li>
              ))}
            </motion.ul>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
