const transition = {
  duration: 0.4
};

export const menuNavVariants = {
  visible: {
    x: 0,
    opacity: 1,
    transition: {
      ...transition
    }
  },
  hidden: {
    x: '100%',
    opacity: 0,
    transition: {
      ...transition
    }
  }
};

export const listVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.15
    }
  }
};

export const listItemVariants = {
  hidden: {
    opacity: 0,
    x: 4
  },
  visible: {
    opacity: 1,
    x: 0
  }
};
