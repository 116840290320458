import clsx from 'clsx';
import { forwardRef, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  className?: string;
  level?: 1 | 2;
}

export const HeaderLink = forwardRef<HTMLAnchorElement, Props>(
  ({ children, className, level = 1, ...rest }, ref) => {
    const linkStyles = clsx(
      'lg:flex lg:space-around inline-block transition-colors outline-0',
      'text-black font-bold text-[3.2rem] lg:text-[6rem] leading-[4.6rem] lg:leading-[8.4rem] whitespace-nowrap',
      'hover:text-hoverPrimary',
      'focus:text-primary',
      'visited:text-primary',
      level === 2 && 'font-normal lg:text-[3.2rem] lg:leading-[4.4rem]',
      className
    );

    return (
      <a {...rest} ref={ref} className={linkStyles}>
        {children}
      </a>
    );
  }
);

HeaderLink.displayName = 'HeaderLink';
