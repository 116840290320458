import clsx from 'clsx';
import { FC } from 'react';

import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';
import { SBLink } from '@/components/shared/SBLink';

import { useToggle } from '@/hooks/useToggle';

import { StoryblokLink } from '@/lib/types/utils';

import { NavigationItemSecondLevel } from '../../types';
import { HeaderLink } from '../HeaderLink';
import { NavigationItemSecondLevelDesktop } from './components/NavigationItemSecondLevelDesktop';
import { NavigationItemSecondLevelMobile } from './components/NavigationItemSecondLevelMobile/NavigationItemSecondLevelMobile';

interface Props {
  name: string;
  link: StoryblokLink;
  node: NavigationItemSecondLevel[];
  backLabel?: string;
}

export const HeaderNavigationItem: FC<Props> = ({ link, name, node, backLabel }) => {
  const [isOpenSubMenu, toggleSubMenu] = useToggle(false);

  const withChildren = node.length > 0;

  const iconStyles = clsx(
    'ml-[2rem] text-primary transition-transform text-primary',
    isOpenSubMenu && 'lg:-rotate-90',
    !isOpenSubMenu && 'lg:rotate-90'
  );

  const submenuClasses = clsx(
    'mt-[1.6rem] space-around',
    isOpenSubMenu && 'flex',
    !isOpenSubMenu && 'hidden'
  );

  return (
    <div className="flex justify-between items-center lg:flex-col lg:items-end">
      <div className="flex justify-center items-center">
        <SBLink link={link}>
          <HeaderLink level={1} className="flex-1">
            {name}
          </HeaderLink>
        </SBLink>
        {withChildren && (
          <button
            className="hidden lg:inline-block px-8 flex justify-center items-center self-stretch"
            onClick={toggleSubMenu}
            title="navigation"
            type="button"
          >
            <ArrowIcon className={iconStyles} />
          </button>
        )}
      </div>

      {withChildren && (
        <>
          <NavigationItemSecondLevelMobile
            node={node}
            isOpen={isOpenSubMenu}
            backLabel={backLabel}
            onMenuClose={() => toggleSubMenu(false)}
          />
          {
            <button
              className="lg:hidden px-8 flex justify-center items-center self-stretch"
              onClick={toggleSubMenu}
              title="navigation"
              type="button"
            >
              <ArrowIcon className={iconStyles} />
            </button>
          }
          <NavigationItemSecondLevelDesktop node={node} className={submenuClasses} />
        </>
      )}
    </div>
  );
};
